import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import PersonalInfoForm from './components/PersonalInfoForm';
import JobDescriptionForm from './components/JobDescriptionForm';
import ResumeDisplay from './components/ResumeDisplay';
import ResumeComparison from './components/ResumeComparison';
import SocialLogin from './components/SocialLogin';
import ProtectedRoute from './components/ProtectedRoute';
import { auth, signOut } from './components/FirebaseConfig';
import { useEffect, useState, useContext } from 'react';
import './App.css';
import { LoadingContext } from './components/LoadingContext';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndServices from './components/TermsAndServices';
import ContactUs from './components/Contact';

function App() {
  const [user, setUser] = useState(null);
  const { loading, setLoading } = useContext(LoadingContext);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    await signOut(auth);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  }

  return (
    <Router>
      <Loader />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        />
      <div className='bg-dark' style={{ color: 'white', minHeight: '100vh', margin: 'auto', textAlign: 'center', fontFamily: 'Kanit', paddingLeft:'50px', paddingRight:'50px' }}>
        <header className='header' style={{ position: 'relative', margin:'auto' }}>
          <div style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 700, fontSize: '3rem', color: 'white', margin: '0' }}>DevSume</div>
          {user && (
            <button onClick={handleLogout} style={{
              position: 'absolute',
              top: '20px',
              right: '10px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              color:'white',
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                  <path fillRule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                </svg>
            </button>
          )}
        </header>
        {user &&
        <ul className="nav nav-pills nav-fill gap-2 p-1 small bg-dark rounded-5 shadow-sm nav-pills-custom" id="pillNav2" role="tablist">
          <li className="nav-item" role="presentation">
            <NavLink to="/me" className={({ isActive }) => `nav-link rounded-5 ${isActive ? 'active' : ''}`} aria-current="page">
              Me
            </NavLink>
          </li>
          <li className="nav-item" role="presentation">
            <NavLink to="/job" className={({ isActive }) => `nav-link rounded-5 ${isActive ? 'active' : ''}`}>
              Job
            </NavLink>
          </li>
          <li className="nav-item" role="presentation">
            <NavLink to="/resume" className={({ isActive }) => `nav-link rounded-5 ${isActive ? 'active' : ''}`}>
              Resume
            </NavLink>
          </li>
          <li className="nav-item" role="presentation">
            <NavLink to="/compare" className={({ isActive }) => `nav-link rounded-5 ${isActive ? 'active' : ''}`}>
              Compare
            </NavLink>
          </li>
        </ul>
        }
        <br />
        <Routes>
          <Route path="/" element={<SocialLogin />} />
          <Route path="/me" element={<ProtectedRoute element={<PersonalInfoForm />} />} />
          <Route path="/job" element={<ProtectedRoute element={<JobDescriptionForm />} />} />
          <Route path="/resume" element={<ProtectedRoute element={<ResumeDisplay />} />} />
          <Route path="/compare" element={<ProtectedRoute element={<ResumeComparison />} />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/terms' element={<TermsAndServices />} />
          <Route path='/contact' element={<ContactUs />} />
        </Routes>
        <br></br>
        <footer className='footer' style={{ padding: '10px 0', background: '#212529', color: 'white', marginTop: 'auto' }}>
          <div style={{ margin: 'auto', textAlign: 'center' }}>
            <p>&copy; 2024 DevSume. All rights reserved.</p>
            <ul className="nav justify-content-center">
              <li className="nav-item">
                <a className="nav-link text-white" href="/privacy">Privacy Policy</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="/terms">Terms of Service</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-white" href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </footer>

        <div onClick={scrollToBottom} style={{ position: 'fixed', right: '2px', bottom: '50px', color: 'white', cursor: 'pointer' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-arrow-down-circle"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293z"
            />
          </svg>
        </div>

        <div onClick={scrollToTop} style={{ position: 'fixed', right: '2px', bottom: '100px', color: 'white', cursor: 'pointer' }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-arrow-up-circle"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"
            />
          </svg>
        </div>
      </div>
    </Router>
  );
}

export default App;
