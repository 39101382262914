import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from './FirebaseConfig';

const ProtectedRoute = ({ element }) => {
  const user = auth.currentUser;

  return user ? element : <Navigate to="/" />;
};

export default ProtectedRoute;
