import React, { useState, useEffect, useRef } from 'react';

const Typewriter = ({ texts, speed = 200, delay = 500 }) => {  
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollRef = useRef(null);

  useEffect(() => {
    const modifiedTexts = texts.map(text => "  " + text);

    if (currentIndex < modifiedTexts.length) {
      let index = 0;
      const intervalId = setInterval(() => {
        setDisplayedText((prev) => prev + modifiedTexts[currentIndex][index]);
        index++;
        if (index === modifiedTexts[currentIndex].length - 1) {
          clearInterval(intervalId);
          setTimeout(() => {
            setDisplayedText(' ');
            setCurrentIndex((prevIndex) => (prevIndex + 1) % modifiedTexts.length);
          }, delay);
        }
      }, speed);

      return () => clearInterval(intervalId);
    }
  }, [currentIndex, texts, speed, delay]);

  return (
      <div>{displayedText}</div>
  );
};

export default Typewriter;

