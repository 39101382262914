import React, { useContext } from 'react';
import RingLoader from "react-spinners/RingLoader";
import { LoadingContext } from './LoadingContext';

const Loader = () => {
  const { loading } = useContext(LoadingContext);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  if (!loading) return null;

  return (
    <div style={styles.backdrop}>
      <RingLoader
        color={"white"}
        loading={loading}
        cssOverride={override}
        size={150}
        aria-label="Hold on while DevSume is doing the magic..."
        data-testid="loader"
      />
    </div>
  );
};

const styles = {
  backdrop: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  }
};

export default Loader;
