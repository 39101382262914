import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { auth } from './FirebaseConfig';
import { LoadingContext } from './LoadingContext';
import Loader from './Loader';
import { toast } from 'react-toastify';
import { logEvent } from 'firebase/analytics';
import { analytics } from './FirebaseConfig';

const JobDescriptionForm = ({ onJobDescriptionSubmit }) => {
  logEvent(analytics, 'Job_Page');
  const navigate = useNavigate();
  const { loading, setLoading } = useContext(LoadingContext);
  const [jobDescription, setJobDescription] = useState('');
  const [companyDetails, setCompanyDetails] = useState({
    companyName: '',
    designation: ''
  });

  useEffect(() => {
    const storedCoverLetter = localStorage.getItem('resumeData');
    if (!storedCoverLetter) {
      const toastId = "resumeWarning";
      if (!toast.isActive(toastId)) {
        toast.warning("You have to save your resume first", { toastId });
        navigate("/me");
      }
    }
  }, [navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompanyDetails({ ...companyDetails, [name]: value });
  };

  const sendResumeForRevision = async (resume, job) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        toast.error("Unauthenticated");
        throw new Error('User is not authenticated');
      }

      const token = await user.getIdToken(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/revise-resume`,
        { resume, job },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { revised_resume, cover_letter } = response.data;
      localStorage.setItem('newResumeData', JSON.stringify(revised_resume));
      localStorage.setItem('coverLetter', cover_letter);
      return true;
    } catch (error) {
      console.log(error);
      if (error.response.status === 429) toast.error("Sorry, daily limit exceeded. Please view the Contact page.");
      else toast.error("Our AI system has gone cuckoo. Please try again later.");
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const storedResume = JSON.parse(localStorage.getItem('resumeData'));
    const job = {
      title: companyDetails.designation,
      company: companyDetails.companyName,
      description: jobDescription
    };

    if (storedResume) {
      const revisedResume = await sendResumeForRevision(storedResume, job);
      if (revisedResume) {
        setLoading(false);
        navigate("/resume");
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Resume not found");
    }
  };

  return (
    <div>
      <Loader />
      <h2>Enter Job Description</h2>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-6'>
            <input
              className='form-control'
              type='text'
              name='companyName'
              value={companyDetails.companyName}
              onChange={handleInputChange}
              placeholder='Company Name'
              required
            />
          </div>
          <div className='col-6'>
            <input
              className='form-control'
              type='text'
              name='designation'
              value={companyDetails.designation}
              onChange={handleInputChange}
              placeholder='Designation'
              required
            />
          </div>
        </div>
        <br />
        <textarea
          value={jobDescription}
          onChange={e => setJobDescription(e.target.value)}
          placeholder="Type the job description here..."
          style={{ width: '100%', height: '250px' }}
          required
        />
        <br />
        <br />
        <div className='row'>
          <div className='col-3'></div>
          <div className='col-6'>
            <button className='btn btn-lg btn-light form-control' type='submit'>
              Continue
            </button>
          </div>
          <div className='col-3'></div>
        </div>
      </form>
    </div>
  );
};

export default JobDescriptionForm;
