import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="container mt-5" style={{textAlign:"left"}}>
            <p><strong>Effective Date:</strong> July 5, 2024</p>
            
            <section>
                <h2>Introduction</h2>
                <p>Welcome to Devsume. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you use our website and services. By using Devsume, you agree to the collection and use of information in accordance with this policy.</p>
                <p>Our Privacy Policy is designed to help you understand how we collect and use your information, how we protect it, and your rights regarding your personal data. We encourage you to read this Privacy Policy carefully and contact us if you have any questions or concerns.</p>
            </section>

            <section>
                <h2>Information We Collect</h2>
                
                <h3>Personal Information</h3>
                <p>When you use our services, we may collect the following personal information:</p>
                <ul>
                    <li><strong>Contact Information:</strong> Name, email address, phone number.</li>
                    <li><strong>Resume Information:</strong> All data contained in your resume, including but not limited to your education, work experience, skills, and personal projects.</li>
                    <li><strong>Payment Information:</strong> Credit card details or other payment information, if applicable.</li>
                    <li><strong>Account Information:</strong> Username, password, and other information necessary for accessing your account.</li>
                </ul>
                <p>We collect this information when you register on our site, place an order, subscribe to our newsletter, fill out a form, or enter information on our site. Providing us with your personal information is voluntary, but it may be necessary for certain features and services.</p>
                
                <h3>Non-Personal Information</h3>
                <p>We may also collect non-personal information, such as:</p>
                <ul>
                    <li>Browser type and version</li>
                    <li>Operating system</li>
                    <li>IP address</li>
                    <li>Pages visited on our site</li>
                    <li>Time and date of visits</li>
                    <li>Referring site details</li>
                    <li>Device information</li>
                    <li>Clickstream data</li>
                </ul>
                <p>This information helps us understand how visitors use our website, which allows us to improve our services and enhance user experience. Non-personal information does not identify you personally and is used in aggregate form.</p>
            </section>

            <section>
                <h2>How We Use Your Information</h2>
                <p>We use the collected information for the following purposes:</p>
                <ul>
                    <li>To provide and improve our resume revision services.</li>
                    <li>To communicate with you regarding your inquiries, service updates, and promotional offers.</li>
                    <li>To process payments for our services.</li>
                    <li>To analyze site usage and improve our website.</li>
                    <li>To comply with legal obligations.</li>
                    <li>To personalize your experience and deliver content and product offerings relevant to your interests.</li>
                    <li>To send periodic emails regarding your order or other products and services.</li>
                </ul>
                <p>We may also use your information to administer a contest, promotion, survey, or other site feature. The legal basis for processing your personal data is your consent, the necessity to perform a contract, and our legitimate interests, such as improving our services and website.</p>
            </section>

            <section>
                <h2>Sharing Your Information</h2>
                <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following circumstances:</p>
                <ul>
                    <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
                    <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
                    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
                    <li><strong>Protection of Rights:</strong> We may disclose your information to enforce our site policies, protect our rights, property, or safety, or the rights, property, or safety of others.</li>
                </ul>
                <p>We ensure that any third parties who have access to your data are required to maintain its confidentiality and security. We will not share your personal information with third parties for their marketing purposes without your explicit consent.</p>
            </section>

            <section>
                <h2>Security</h2>
                <p>We implement a variety of security measures to maintain the safety of your personal information. These measures include encryption, access controls, and secure servers. However, no method of transmission over the internet, or method of electronic storage, is 100% secure, and we cannot guarantee its absolute security.</p>
                <p>We encourage you to take steps to protect your personal information, such as using strong passwords, keeping your login information confidential, and logging out of your account when you are finished using our site. If you suspect any unauthorized access to your account, please contact us immediately.</p>
            </section>

            <section>
                <h2>Cookies</h2>
                <p>We use cookies to enhance your experience on our site. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's systems to recognize your browser and capture and remember certain information.</p>
                <p>We use cookies to:</p>
                <ul>
                    <li>Understand and save your preferences for future visits.</li>
                    <li>Compile aggregate data about site traffic and site interactions to offer better site experiences and tools in the future.</li>
                    <li>Keep track of advertisements.</li>
                </ul>
                <p>You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. If you disable cookies, some features will be disabled. It won't affect the user experience that makes your site experience more efficient and may not function properly.</p>
            </section>

            <section>
                <h2>Third-Party Links</h2>
                <p>Our website may contain links to third-party sites. We are not responsible for the privacy practices or the content of such sites. We encourage you to read the privacy policies of any linked sites you visit.</p>
            </section>

            <section>
                <h2>Children's Privacy</h2>
                <p>Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that a child under 18 has provided us with personal information, we will take steps to delete such information.</p>
            </section>

            <section>
                <h2>Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.</p>
            </section>

            <section>
                <h2>Your Consent</h2>
                <p>By using our site, you consent to our website's privacy policy.</p>
            </section>

            <section>
                <h2>Contacting Us</h2>
                <p>If there are any questions regarding this privacy policy, you may contact us using the information below:</p>
                <p>Devsume<br/>
                   [ashique.kainote@gmail.com]<br/></p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
