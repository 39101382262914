import React from 'react';

const TermsAndServices = () => {
    return (
        <div className="container mt-5" style={{textAlign:"left"}}>
            <p><strong>Effective Date:</strong> July 5, 2024</p>
            
            <section>
                <h2>Introduction</h2>
                <p>Welcome to Devsume. These Terms and Services ("Terms") govern your use of our website and services. By accessing or using Devsume, you agree to comply with and be bound by these Terms. Please read them carefully. If you do not agree to these Terms, you should not use our services.</p>
            </section>

            <section>
                <h2>Use of Services</h2>
                <p>Devsume provides a platform for revising resumes and related services. You agree to use our services only for lawful purposes and in accordance with these Terms. You must not use our services:</p>
                <ul>
                    <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
                    <li>To exploit, harm, or attempt to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                    <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter", "spam", or any other similar solicitation.</li>
                    <li>To impersonate or attempt to impersonate Devsume, a Devsume employee, another user, or any other person or entity.</li>
                    <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the services, or which, as determined by us, may harm Devsume or users of the services, or expose them to liability.</li>
                </ul>
            </section>

            <section>
                <h2>User Accounts</h2>
                <p>To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.</p>
            </section>

            <section>
                <h2>Intellectual Property</h2>
                <p>The content, features, and functionality of Devsume are and will remain the exclusive property of Devsume and its licensors. The service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Devsume.</p>
            </section>

            <section>
                <h2>Termination</h2>
                <p>We may terminate or suspend your account and bar access to the services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms. If you wish to terminate your account, you may simply discontinue using the services.</p>
            </section>

            <section>
                <h2>Limitation of Liability</h2>
                <p>In no event shall Devsume, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the service; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our service by any third party; and/or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the service, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
            </section>

            <section>
                <h2>Governing Law</h2>
                <p>These Terms shall be governed and construed in accordance with the laws of the Emirates of Dubai, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our service, and supersede and replace any prior agreements we might have had between us regarding the service.</p>
            </section>

            <section>
                <h2>Changes to These Terms</h2>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. By continuing to access or use our service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the service.</p>
            </section>

            <section>
                <h2>Contact Us</h2>
                <p>If you have any questions about these Terms, please contact us:</p>
                <p>Devsume<br/>
                   [ashique.kainote@gmail.com]<br/></p>
            </section>
        </div>
    );
};

export default TermsAndServices;
