import React from 'react';

const ContactUs = () => {
    return (
        <div className="container mt-5" style={{textAlign:"left"}}>    
            <section>
                <h2>We're Here to Help!</h2>
                <p>If you have any questions, concerns, or feedback, please feel free to reach out to us. We value your input and are here to assist you with any issues you may encounter.</p>
            </section>

            <section>
                <h2>Future Plans</h2>
                <p>We are excited to announce that we plan to integrate a payment gateway into Devsume in the near future. This will help us manage the costs of running the application and allow us to offer more comprehensive services without the current limitations.</p>
                <p>We're seeing options for more ATS friendly generations and scoring on the platform. We understand that the system currently is customised for developers, we'll also be including other formats and extending our support to also other industries soon.</p>
                <p>We sincerely regret that the 5 resumes/day limitation, but unfortunately running and maintaining the website is a costly accord. We appreciate your understanding and support as we work towards these improvements.</p>
                <p>If you think the limits are hindering your job hunt, please do not hesitate to contact us. We'll extend your limits in return for suggestions and opinions, or bugs!!! Good Luck, cracking that interview :) </p>
            </section>

            <section>
                <h2>Contact Information</h2>
                <p>You can reach us through the following channels:</p>
                <ul>
                    <li><strong>Email:</strong> ashique.kainote@gmail.com</li>
                </ul>
            </section>
        </div>
    );
};

export default ContactUs;
