import React, { useState, useEffect, useContext } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';
import { auth, db } from './FirebaseConfig';
import { doc, setDoc, getDoc } from "firebase/firestore"; 
import { LoadingContext } from './LoadingContext';
import Loader from './Loader';
import { toast } from 'react-toastify';
import styles from '../styles/PersonalInfo.module.css';
import axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { analytics } from './FirebaseConfig';

const PersonalInfoForm = () => {
  logEvent(analytics, 'Me_Page');
  const navigate = useNavigate();
  const multiselectRef = React.createRef();
  const { loading, setLoading } = useContext(LoadingContext);
  const [urlIsValid, setUrlIsValid] = useState(true);
  const [usernameIsValid, setUsernameIsValid] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    basics: {
      name: '',
      label: '',
      image: '',
      email: '',
      phone: '',
      url: '',
      summary: '',
      location: {
        address: '',
        postalCode: '',
        city: '',
        countryCode: '',
        region: ''
      },
      profiles: [{ network: '', username: '', url: '' }]
    },
    work: [{ name: '', position: '', url: '', startDate: '', endDate: '', summary: '', highlights: '' }],
    skills: [{ name: '', level: '', keywords: [] }],
    education: [{ institution: '', url: '', area: '', studyType: '', startDate: '', endDate: '', score: '' }],
    awards: [{ title: '', date: '', awarder: '', summary: '' }],
    certificates: [{ name: '', date: '', issuer: '', url: '' }],
    publications: [{ name: '', publisher: '', releaseDate: '', url: '', summary: '' }],
    volunteer: [{ organization: '', position: '', url: '', startDate: '', endDate: '', summary: '' }],
    languages: [{ language: '', fluency: '' }],
    interests: [{ name: '', keywords: [] }],
    references: [{ name: '', reference: '' }],
    projects: [{ name: '', startDate: '', endDate: '', description:'', url: '' }]
  });
  

  const urlRegex = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
  const usernameRegex = /^@[a-zA-Z0-9_]+$/;

  const [visibleSections, setVisibleSections] = useState({
    work: false,
    volunteer: false,
    awards: false,
    certificates: false,
    publications: false,
    languages: false,
    interests: false,
    references: false,
    projects: false,
    profile: false
  });

  const [skillsOptions, setSkillsOptions] = useState([
    { key: 'programminglanguages', value: 'Programming Languages' },
    { key: 'webdevelopment', value: 'Web Development' },
    { key: 'datascience', value: 'Data Science' },
    { key: 'devops', value: 'DevOps' },
    { key: 'databasemanagement', value: 'Database Management' },
    { key: 'cloudcomputing', value: 'Cloud Computing' },
    { key: 'softwaretesting', value: 'Software Testing' },
    { key: 'agilemethodologies', value: 'Agile Methodologies' },
    { key: 'projectmanagement', value: 'Project Management' },
    { key: 'cybersecurity', value: 'Cyber Security' },
    { key: 'networkadministration', value: 'Network Administration' },
    { key: 'infrastructuremanagement', value: 'Infrastructure Management' },
    { key: 'systemsarchitecture', value: 'Systems Architecture' },
    { key: 'mobiledevelopment', value: 'Mobile Development' },
    { key: 'uiuxdesign', value: 'UI/UX Design' },
    { key: 'gamedevelopment', value: 'Game Development' },
    { key: 'machinelearning', value: 'Machine Learning' },
    { key: 'artificialintelligence', value: 'Artificial Intelligence' },
    { key: 'bigdata', value: 'Big Data' },
    { key: 'businessintelligence', value: 'Business Intelligence' },
    { key: 'itsupport', value: 'IT Support' },
    { key: 'embeddedsystems', value: 'Embedded Systems' },
    { key: 'blockchain', value: 'Blockchain' },
    { key: 'robotics', value: 'Robotics' },
    { key: 'iot', value: 'IOT (Internet of Things)' },
    { key: 'enterprisesoftware', value: 'Enterprise Software' },
    { key: 'erpsystems', value: 'ERP Systems' },
    { key: 'crmsystems', value: 'CRM Systems' },
    { key: 'apidevelopment', value: 'API Development' },
    { key: 'microservices', value: 'Microservices' },
    { key: 'containerization', value: 'Containerization' },
    { key: 'serverlesscomputing', value: 'Serverless Computing' },
    { key: 'cicd', value: 'Continuous Integration/Continuous Deployment (CI/CD)' },
    { key: 'sre', value: 'Site Reliability Engineering (SRE)' },
    { key: 'qualityassurance', value: 'Quality Assurance' },
    { key: 'performancetesting', value: 'Performance Testing' },
    { key: 'automationtesting', value: 'Automation Testing' },
    { key: 'penetrationtesting', value: 'Penetration Testing' },
    { key: 'riskmanagement', value: 'Risk Management' },
    { key: 'compliance', value: 'Compliance' },
    { key: 'governance', value: 'Governance' },
    { key: 'datagovernance', value: 'Data Governance' },
    { key: 'dataprivacy', value: 'Data Privacy' },
    { key: 'ethicalhacking', value: 'Ethical Hacking' },
    { key: 'incidentresponse', value: 'Incident Response' },
    { key: 'disasterrecovery', value: 'Disaster Recovery' },
    { key: 'networksecurity', value: 'Network Security' },
    { key: 'applicationsecurity', value: 'Application Security' },
    { key: 'informationsecurity', value: 'Information Security' },
    { key: 'systemsintegration', value: 'Systems Integration' },
    { key: 'virtualization', value: 'Virtualization' },
    { key: 'storagemanagement', value: 'Storage Management' },
    { key: 'backuprecovery', value: 'Backup and Recovery' },
    { key: 'highavailability', value: 'High Availability' },
    { key: 'loadbalancing', value: 'Load Balancing' },
    { key: 'monitoringlogging', value: 'Monitoring and Logging' },
    { key: 'capacityplanning', value: 'Capacity Planning' },
    { key: 'configurationmanagement', value: 'Configuration Management' },
    { key: 'changemanagement', value: 'Change Management' },
    { key: 'releasemanagement', value: 'Release Management' },
    { key: 'servicemanagement', value: 'Service Management' },
    { key: 'itil', value: 'ITIL (Information Technology Infrastructure Library)' },
    { key: 'cobit', value: 'COBIT (Control Objectives for Information and Related Technologies)' }
  ]);
  

  const [skillKeywords, setSkillKeywords] = useState([
    // Programming Languages
    { name: 'Python', id: 'Python' },
    { name: 'Java', id: 'Java' },
    { name: 'C++', id: 'C++' },
    { name: 'C#', id: 'C#' },
    { name: 'Go', id: 'Go' },
    { name: 'Ruby', id: 'Ruby' },
    { name: 'Swift', id: 'Swift' },
    { name: 'Kotlin', id: 'Kotlin' },
    { name: 'PHP', id: 'PHP' },
    { name: 'TypeScript', id: 'TypeScript' },
    
    // Web Development
    { name: 'HTML', id: 'HTML' },
    { name: 'CSS', id: 'CSS' },
    { name: 'JavaScript', id: 'JavaScript' },
    { name: 'React', id: 'React' },
    { name: 'Angular', id: 'Angular' },
    { name: 'Vue.js', id: 'Vue.js' },
    { name: 'Node.js', id: 'Node.js' },
    { name: 'Django', id: 'Django' },
    { name: 'Flask', id: 'Flask' },
    { name: 'ASP.NET', id: 'ASP.NET' },
    
    // Data Science
    { name: 'R', id: 'R' },
    { name: 'Pandas', id: 'Pandas' },
    { name: 'NumPy', id: 'NumPy' },
    { name: 'Scikit-Learn', id: 'Scikit-Learn' },
    { name: 'TensorFlow', id: 'TensorFlow' },
    { name: 'Keras', id: 'Keras' },
    { name: 'Matplotlib', id: 'Matplotlib' },
    { name: 'Seaborn', id: 'Seaborn' },
    
    // DevOps
    { name: 'Docker', id: 'Docker' },
    { name: 'Kubernetes', id: 'Kubernetes' },
    { name: 'Jenkins', id: 'Jenkins' },
    { name: 'Ansible', id: 'Ansible' },
    { name: 'Terraform', id: 'Terraform' },
    { name: 'GitLab', id: 'GitLab' },
    
    // Database Management
    { name: 'SQL', id: 'SQL' },
    { name: 'NoSQL', id: 'NoSQL' },
    { name: 'MySQL', id: 'MySQL' },
    { name: 'PostgreSQL', id: 'PostgreSQL' },
    { name: 'MongoDB', id: 'MongoDB' },
    { name: 'SQLite', id: 'SQLite' },
    { name: 'Redis', id: 'Redis' },
    { name: 'Oracle', id: 'Oracle' },
    { name: 'Microsoft SQL Server', id: 'Microsoft SQL Server' },
    
    // Cloud Computing
    { name: 'AWS', id: 'AWS' },
    { name: 'Azure', id: 'Azure' },
    { name: 'Google Cloud', id: 'Google Cloud' },
    { name: 'IBM Cloud', id: 'IBM Cloud' },
    { name: 'Oracle Cloud', id: 'Oracle Cloud' },
    
    // Software Testing
    { name: 'Selenium', id: 'Selenium' },
    { name: 'JUnit', id: 'JUnit' },
    { name: 'TestNG', id: 'TestNG' },
    { name: 'Postman', id: 'Postman' },
    { name: 'Cucumber', id: 'Cucumber' },
    { name: 'Jest', id: 'Jest' },
    
    // Agile Methodologies
    { name: 'Scrum', id: 'Scrum' },
    { name: 'Kanban', id: 'Kanban' },
    { name: 'SAFe', id: 'SAFe' },
    { name: 'XP', id: 'XP' },
    
    // Project Management
    { name: 'JIRA', id: 'JIRA' },
    { name: 'Trello', id: 'Trello' },
    { name: 'Asana', id: 'Asana' },
    { name: 'MS Project', id: 'MS Project' },
    
    // Cyber Security
    { name: 'Penetration Testing', id: 'Penetration Testing' },
    { name: 'Risk Management', id: 'Risk Management' },
    { name: 'Compliance', id: 'Compliance' },
    { name: 'Governance', id: 'Governance' },
    { name: 'Data Governance', id: 'Data Governance' },
    { name: 'Data Privacy', id: 'Data Privacy' },
    { name: 'Ethical Hacking', id: 'Ethical Hacking' },
    { name: 'Incident Response', id: 'Incident Response' },
    { name: 'Disaster Recovery', id: 'Disaster Recovery' },
    { name: 'Network Security', id: 'Network Security' },
    { name: 'Application Security', id: 'Application Security' },
    { name: 'Information Security', id: 'Information Security' },
    
    // Network Administration
    { name: 'Cisco', id: 'Cisco' },
    { name: 'Juniper', id: 'Juniper' },
    { name: 'Palo Alto', id: 'Palo Alto' },
    { name: 'Firewall', id: 'Firewall' },
    
    // Infrastructure Management
    { name: 'VMware', id: 'VMware' },
    { name: 'Hyper-V', id: 'Hyper-V' },
    { name: 'OpenStack', id: 'OpenStack' },
    
    // Systems Architecture
    { name: 'Microservices', id: 'Microservices' },
    { name: 'SOA', id: 'SOA' },
    
    // Mobile Development
    { name: 'Android', id: 'Android' },
    { name: 'iOS', id: 'iOS' },
    { name: 'React Native', id: 'React Native' },
    { name: 'Flutter', id: 'Flutter' },
    
    // UI/UX Design
    { name: 'Adobe XD', id: 'Adobe XD' },
    { name: 'Sketch', id: 'Sketch' },
    { name: 'Figma', id: 'Figma' },
    
    // Game Development
    { name: 'Unity', id: 'Unity' },
    { name: 'Unreal Engine', id: 'Unreal Engine' },
    
    // Machine Learning & Artificial Intelligence
    { name: 'Machine Learning', id: 'Machine Learning' },
    { name: 'Deep Learning', id: 'Deep Learning' },
    { name: 'Natural Language Processing', id: 'Natural Language Processing' },
    { name: 'Computer Vision', id: 'Computer Vision' },
    
    // Big Data
    { name: 'Hadoop', id: 'Hadoop' },
    { name: 'Spark', id: 'Spark' },
    { name: 'Kafka', id: 'Kafka' },
    
    // Business Intelligence
    { name: 'Tableau', id: 'Tableau' },
    { name: 'Power BI', id: 'Power BI' },
    { name: 'QlikView', id: 'QlikView' },
    
    // IT Support
    { name: 'Help Desk', id: 'Help Desk' },
    { name: 'Technical Support', id: 'Technical Support' },
    
    // Embedded Systems
    { name: 'Arduino', id: 'Arduino' },
    { name: 'Raspberry Pi', id: 'Raspberry Pi' },
    
    // Blockchain
    { name: 'Ethereum', id: 'Ethereum' },
    { name: 'Solidity', id: 'Solidity' },
    { name: 'Hyperledger', id: 'Hyperledger' },
    
    // Robotics
    { name: 'ROS', id: 'ROS' },
    { name: 'Automation', id: 'Automation' },
    
    // IoT (Internet of Things)
    { name: 'IoT', id: 'IoT' },
    { name: 'MQTT', id: 'MQTT' },
    { name: 'Zigbee', id: 'Zigbee' },
    
    // Enterprise Software
    { name: 'SAP', id: 'SAP' },
    { name: 'Oracle E-Business Suite', id: 'Oracle E-Business Suite' },
    
    // ERP Systems
    { name: 'ERP', id: 'ERP' },
    { name: 'Odoo', id: 'Odoo' },
    
    // CRM Systems
    { name: 'Salesforce', id: 'Salesforce' },
    { name: 'HubSpot', id: 'HubSpot' },
    
    // API Development
    { name: 'REST', id: 'REST' },
    { name: 'GraphQL', id: 'GraphQL' },
    
    // Microservices
    { name: 'Docker', id: 'Docker' },
    { name: 'Kubernetes', id: 'Kubernetes' },
    
    // Containerization
    { name: 'Docker', id: 'Docker' },
    { name: 'Kubernetes', id: 'Kubernetes' },
    
    // Serverless Computing
    { name: 'AWS Lambda', id: 'AWS Lambda' },
    { name: 'Azure Functions', id: 'Azure Functions' },
    
    // CI/CD
    { name: 'Jenkins', id: 'Jenkins' },
    { name: 'GitLab CI', id: 'GitLab CI' },
    
    // SRE
    { name: 'Site Reliability Engineering', id: 'Site Reliability Engineering' },
    
    // Quality Assurance
    { name: 'Selenium', id: 'Selenium' },
    { name: 'QTP', id: 'QTP' },
    
    // Performance Testing
    { name: 'JMeter', id: 'JMeter' },
    { name: 'LoadRunner', id: 'LoadRunner' },
    
    // Automation Testing
    { name: 'Selenium', id: 'Selenium' },
    { name: 'Cypress', id: 'Cypress' },
    
    // Penetration Testing
    { name: 'Metasploit', id: 'Metasploit' },
    { name: 'Burp Suite', id: 'Burp Suite' },
    
    // Risk Management
    { name: 'Risk Assessment', id: 'Risk Assessment' },
    
    // Compliance
    { name: 'PCI DSS', id: 'PCI DSS' },
    { name: 'HIPAA', id: 'HIPAA' },
    
    // Governance
    { name: 'ISO 27001', id: 'ISO 27001' },
    
    // Data Governance
    { name: 'Data Classification', id: 'Data Classification' },
    
    // Data Privacy
    { name: 'GDPR', id: 'GDPR' },
    { name: 'CCPA', id: 'CCPA' },
    
    // Ethical Hacking
    { name: 'CEH', id: 'CEH' },
    
    // Incident Response
    { name: 'Incident Management', id: 'Incident Management' },
    
    // Disaster Recovery
    { name: 'DRP', id: 'DRP' },
    
    // Network Security
    { name: 'Firewall', id: 'Firewall' },
    
    // Application Security
    { name: 'OWASP', id: 'OWASP' },
    
    // Information Security
    { name: 'CISSP', id: 'CISSP' },
    
    // Systems Integration
    { name: 'API Integration', id: 'API Integration' },
    
    // Virtualization
    { name: 'VMware', id: 'VMware' },
    
    // Storage Management
    { name: 'SAN', id: 'SAN' },
    
    // Backup and Recovery
    { name: 'Backup Solutions', id: 'Backup Solutions' },
    
    // High Availability
    { name: 'Clustering', id: 'Clustering' },
    
    // Load Balancing
    { name: 'F5', id: 'F5' },
    
    // Monitoring and Logging
    { name: 'Nagios', id: 'Nagios' },
    { name: 'Prometheus', id: 'Prometheus' },
    
    // Capacity Planning
    { name: 'Capacity Management', id: 'Capacity Management' },
    
    // Configuration Management
    { name: 'Puppet', id: 'Puppet' },
    
    // Change Management
    { name: 'ITIL', id: 'ITIL' },
    
    // Release Management
    { name: 'Release Planning', id: 'Release Planning' },
    
    // Service Management
    { name: 'ServiceNow', id: 'ServiceNow' },
    
    // ITIL
    { name: 'ITIL Foundation', id: 'ITIL Foundation' },
    
    // COBIT
    { name: 'COBIT 5', id: 'COBIT 5' },

      // IDEs and Development Tools
  { name: 'Visual Studio', id: 'Visual Studio' },
  { name: 'Visual Studio Code', id: 'Visual Studio Code' },
  { name: 'Eclipse', id: 'Eclipse' },
  { name: 'IntelliJ IDEA', id: 'IntelliJ IDEA' },
  { name: 'PyCharm', id: 'PyCharm' },
  
  // Frameworks and Libraries
  { name: '.NET Core', id: '.NET Core' },
  { name: '.NET Framework', id: '.NET Framework' },
  { name: 'MAUI', id: 'MAUI' },
  { name: 'XAML', id: 'XAML' },
  { name: 'WPF', id: 'WPF' },
  { name: 'WCF', id: 'WCF' },
  { name: 'SpringBoot', id: 'SpringBoot' },
  { name: 'Hibernate', id: 'Hibernate' },
  { name: 'Express.js', id: 'Express.js' },
  { name: 'Laravel', id: 'Laravel' },
  { name: 'Symfony', id: 'Symfony' },
  { name: 'Bootstrap', id: 'Bootstrap' },
  { name: 'jQuery', id: 'jQuery' },
  
  // Testing Frameworks
  { name: 'XUnit', id: 'XUnit' },
  { name: 'NUnit', id: 'NUnit' },
  { name: 'Jasmine', id: 'Jasmine' },
  { name: 'Mocha', id: 'Mocha' },
  { name: 'Chai', id: 'Chai' },
  { name: 'Protractor', id: 'Protractor' },
  { name: 'Cypress', id: 'Cypress' },
  { name: 'Robot Framework', id: 'Robot Framework' },
  
  // Additional Libraries and Tools
  { name: 'Selenium', id: 'Selenium' },
  { name: 'Appium', id: 'Appium' },
  { name: 'TestComplete', id: 'TestComplete' },
  { name: 'Ranorex', id: 'Ranorex' },
  { name: 'Katalon Studio', id: 'Katalon Studio' },
  { name: 'Jira', id: 'Jira' },
  { name: 'Confluence', id: 'Confluence' },
  { name: 'Bitbucket', id: 'Bitbucket' },
  { name: 'SVN', id: 'SVN' },
  
  // Continuous Integration and Continuous Deployment (CI/CD)
  { name: 'CircleCI', id: 'CircleCI' },
  { name: 'Travis CI', id: 'Travis CI' },
  { name: 'Bamboo', id: 'Bamboo' },
  { name: 'TeamCity', id: 'TeamCity' },
  { name: 'Azure DevOps', id: 'Azure DevOps' },
  
  // Configuration Management
  { name: 'Chef', id: 'Chef' },
  { name: 'SaltStack', id: 'SaltStack' },
  
  // Monitoring and Logging
  { name: 'ELK Stack', id: 'ELK Stack' },
  { name: 'Splunk', id: 'Splunk' },
  { name: 'New Relic', id: 'New Relic' },
  { name: 'Datadog', id: 'Datadog' },
  { name: 'Grafana', id: 'Grafana' },
  
  // Containerization and Orchestration
  { name: 'Podman', id: 'Podman' },
  { name: 'OpenShift', id: 'OpenShift' },
  
  // Security Tools
  { name: 'Nessus', id: 'Nessus' },
  { name: 'Wireshark', id: 'Wireshark' },
  { name: 'Snort', id: 'Snort' },
  { name: 'OSSEC', id: 'OSSEC' },
  { name: 'Nmap', id: 'Nmap' },
  
  // Additional Cloud Platforms
  { name: 'Alibaba Cloud', id: 'Alibaba Cloud' },
  { name: 'DigitalOcean', id: 'DigitalOcean' },
  
  // Other Notable Tools
  { name: 'Docker Swarm', id: 'Docker Swarm' },
  { name: 'Vagrant', id: 'Vagrant' },
  { name: 'Packer', id: 'Packer' },
  { name: 'Consul', id: 'Consul' },
  { name: 'Vault', id: 'Vault' },
  
  // Machine Learning and Data Science Tools
  { name: 'PyTorch', id: 'PyTorch' },
  { name: 'H2O.ai', id: 'H2O.ai' },
  { name: 'DataRobot', id: 'DataRobot' },
  { name: 'BigML', id: 'BigML' },
  
  // Big Data Technologies
  { name: 'Flink', id: 'Flink' },
  { name: 'Storm', id: 'Storm' },
  { name: 'Samza', id: 'Samza' },
  
  // Miscellaneous
  { name: 'Unity', id: 'Unity' },
  { name: 'Unreal Engine', id: 'Unreal Engine' },
  { name: 'Firebase', id: 'Firebase' },
  { name: 'Parse', id: 'Parse' },
  { name: 'Realm', id: 'Realm' }
  ]);

  const levelOptions = ['Beginner', 'Intermediate', 'Advanced'];
  const [profileNetworkOptions, setProfileNetworkOptions] = useState([
    { key: 'github', value: 'Github' },
    { key: 'linkedin', value: 'LinkedIn' },
    { key: 'stackoverflow', value: 'StackOverflow' },
    { key: 'leetcode', value: 'LeetCode' },
    { key: 'hackerrank', value: 'HackerRank' },
    { key: 'codechef', value: 'CodeChef' },
    { key: 'codeforces', value: 'Codeforces' },
    { key: 'topcoder', value: 'TopCoder' },
    { key: 'kaggle', value: 'Kaggle' },
    { key: 'gitlab', value: 'GitLab' },
    { key: 'bitbucket', value: 'Bitbucket' },
    { key: 'medium', value: 'Medium' },
    { key: 'devto', value: 'Dev.to' },
    { key: 'hashnode', value: 'Hashnode' },
    { key: 'twitter', value: 'Twitter' },
    { key: 'facebook', value: 'Facebook' },
    { key: 'reddit', value: 'Reddit' }
  ]);

  useEffect(() => {
    setLoading(true);
    const fetchResume = async () => {
      if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        const docRef = doc(db, "resumes", userId);
        const docSnap = await getDoc(docRef);
        const newKeywords = [];
        const newSkillsOptions = [];
        
        if (docSnap.exists()) {
          const existingSkillKeys = skillsOptions.map(option => option.key.toLowerCase());
          
          for (const skill of docSnap.data().skills) {
            for (const keyword of skill.keywords) {
              if (!skillKeywords.find(f => f.id.toLowerCase() === keyword.toLowerCase())) {
                newKeywords.push({ name: keyword, id: keyword });
              }
            }
            
            const normalizedSkillName = skill.name.toLowerCase().replace(/\s+/g, '');
            if (!existingSkillKeys.includes(normalizedSkillName)) {
              newSkillsOptions.push({ key: normalizedSkillName, value: skill.name });
            }
          }
          
          setSkillKeywords(prevSkills => [...prevSkills, ...newKeywords]);
          setSkillsOptions(prevSkills => [...prevSkills, ...newSkillsOptions]);
          setFormData(docSnap.data());
          setLoading(false);
          
        } else {
          setLoading(false);
        }
      }
    };
    fetchResume();
  }, [auth.currentUser, setFormData, setSkillKeywords, setSkillsOptions]);
  

  useEffect(() => {
    setSectionVisibility();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split('.');

    setFormData((prevData) => {
      let updatedData = { ...prevData };
      let currentLevel = updatedData;

      keys.forEach((key, index) => {
        if (key === 'url' && (value === '' || urlRegex.test(value))) setUrlIsValid(false);
        if (key === 'username' && usernameRegex.test(value)) setUsernameIsValid(false);
        if (index === keys.length - 1) {
          currentLevel[key] = value;
        } else {
          currentLevel = currentLevel[key];
        }
      });

      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!urlIsValid) {toast.error("Wrong URL format"); return;}
    if (!usernameIsValid) {toast.error("Username should start with @ "); return;}

    const updatedFormData = {
      ...formData,
      skills: formData.skills.length > 0 ? formData.skills.map((skill) => ({
        ...skill,
        keywords: skill.keywords || []
      })) : formData.skills
    };
    localStorage.setItem('resumeData', JSON.stringify(updatedFormData));

    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      await setDoc(doc(db, "resumes", userId), updatedFormData);
    }

    navigate("/job");
  };

  const setSectionVisibility = () => {
    const updatedVisibility = { ...visibleSections };
  
    for (const section in formData) {
      if (section !== 'basics' && formData[section].some(item => {
        return Object.values(item).some(value => {
          if (Array.isArray(value)) {
            return value.length > 0 && value.some(subValue => subValue !== '');
          }
          return value !== '';
        });
      })) {
        updatedVisibility[section] = true;
      } else {
        updatedVisibility[section] = false;
      }
      if (section == 'basics')
      {
        if (formData[section].profiles.length > 0 && formData[section].profiles.some(subValue => subValue !== ''))
            updatedVisibility['profile'] = true;
      }
    }
  
    setVisibleSections(updatedVisibility);
  };

  const handleArrayChange = (section, index, event) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const newArray = [...prevData[section]];
      newArray[index][name] = value;
      return { ...prevData, [section]: newArray };
    });
  };

  const handleNestedArrayChange = (section, nestedSection, index, event) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      const newNestedArray = [...prevData[section][nestedSection]];
      newNestedArray[index][name] = value;
      return {
        ...prevData,
        [section]: {
          ...prevData[section],
          [nestedSection]: newNestedArray
        }
      };
    });
  };

  const handleKeywordsChange = (list, index) => {
    setFormData((prevData) => {
      const newSkills = [...prevData.skills];
      newSkills[index].keywords = list.map(keyword => keyword.id);
      return { ...prevData, skills: newSkills };
    });
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) {
      setError('Please select a file to upload');
      return;
    }

    if (selectedFile.type !== 'application/pdf') {
      setError('Only PDF files are allowed');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      toast.error("Unauthenticated");
      throw new Error('User is not authenticated');
    }

    const token = await user.getIdToken(true);

    const uploadData = new FormData();
    uploadData.append('file', selectedFile);

    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/parse-resume`, uploadData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
    
      const normalizeString = (str) => {
        return str.toLowerCase().replace(/\s+/g, '');
      };
    
      const normalizeProfiles = (profiles) => {
        return profiles.map(profile => ({
          ...profile,
          network: normalizeString(profile.network)
        }));
      };
    
      const normalizeSkills = (skills) => {
        return skills.map(skill => ({
          ...skill,
          name: normalizeString(skill.name)
        }));
      };
    
      const existingSkillKeys = skillsOptions.map(option => option.key.toLowerCase());
      const newKeywords = [];
      const newSkillsOptions = [];
      
      for (const skill of response.data.skills) {
        for (const keyword of skill.keywords) {
          if (!skillKeywords.find(f => f.id.toLowerCase() === keyword.toLowerCase())) {
            newKeywords.push({ name: keyword, id: keyword });
          }
        }
        
        const normalizedSkillName = normalizeString(skill.name);
        if (!existingSkillKeys.includes(normalizedSkillName)) {
          newSkillsOptions.push({ key: normalizedSkillName, value: skill.name });
        }
      }
    
      const normalizedData = {
        ...response.data,
        basics: {
          ...response.data.basics,
          profiles: normalizeProfiles(response.data.basics.profiles)
        },
        skills: normalizeSkills(response.data.skills)
      };
    
      setSkillKeywords(prevSkills => [...prevSkills, ...newKeywords]);
      setSkillsOptions(prevSkills => [...prevSkills, ...newSkillsOptions]);
      setFormData(normalizedData);
      setError(null);
      setLoading(false);
    } catch (err) {
      setError('Error uploading file');
      setLoading(false);
    }
    
    

  };

  const handleNestedArrayAddClick = (section, nestedSection, newItem) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [nestedSection]: [...prevData[section][nestedSection], newItem]
      }
    }));
  };

  const handleArrayAddClick = (section, newItem) => {
    setFormData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], newItem]
    }));
  };

  const handleArrayRemoveClick = (section, index) => {
    setFormData((prevData) => {
      const newArray = [...prevData[section]];
      newArray.splice(index, 1);
      return { ...prevData, [section]: newArray };
    });
  };

  const handleNestedArrayRemoveClick = (section, nestedSection, index) => {
    setFormData((prevData) => {
      const newNestedArray = [...prevData[section][nestedSection]];
      newNestedArray.splice(index, 1);
      return {
        ...prevData,
        [section]: {
          ...prevData[section],
          [nestedSection]: newNestedArray
        }
      };
    });
  };    

  const afterSearch = (keyword, index) => {
    if (skillKeywords.filter(sk => sk.id.toLowerCase().includes(keyword.toLowerCase())).length === 0) {
        setSkillKeywords((prevSkills) => {return [...prevSkills ?? [], { name: `${keyword}`, id: `${keyword}` }]});
        multiselectRef.current.state.filteredOptions = [{ name: `${keyword}`, id: `${keyword}` }];
    }
  }

  const toggleSectionVisibility = (section) => {
    setVisibleSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const requiredFieldClass = 'form-control mb-3 required-field';

  const getValue = (value) => value || '';

  return (
    <div>

<div className='resumeuploader'>
      <h4>Upload Resume(PDF)</h4>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleFileUpload}>Upload</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>

    <form onSubmit={handleSubmit} style={{ textAlign: 'left' }}>
      <div className='section mb-5'>
        <h2>Basics</h2>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Full Name<span className="text-danger">*</span></label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className={requiredFieldClass} type='text' name='basics.name' value={getValue(formData.basics.name)} onChange={handleChange} placeholder='Full Name' required />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Designation<span className="text-danger">*</span></label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className={requiredFieldClass} type='text' name='basics.label' value={getValue(formData.basics.label)} onChange={handleChange} placeholder='Label' required />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Email<span className="text-danger">*</span></label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className={requiredFieldClass} type='email' name='basics.email' value={getValue(formData.basics.email)} onChange={handleChange} placeholder='Email' required />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Phone<span className="text-danger">*</span></label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className={requiredFieldClass} type='tel' name='basics.phone' value={getValue(formData.basics.phone)} onChange={handleChange} placeholder='Phone' required />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Website</label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className='form-control mb-3' type='text' name='basics.url' value={getValue(formData.basics.url)} onChange={handleChange} placeholder='Website' />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>City<span className="text-danger">*</span></label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className={requiredFieldClass} type='text' name='basics.location.city' value={getValue(formData.basics.location.city)} onChange={handleChange} placeholder='City' required />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Address</label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className='form-control mb-3' type='text' name='basics.location.address' value={getValue(formData.basics.location.address)} onChange={handleChange} placeholder='Address' />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Postal Code</label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className='form-control mb-3' type='text' name='basics.location.postalCode' value={getValue(formData.basics.location.postalCode)} onChange={handleChange} placeholder='Postal Code' />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Country Code</label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className='form-control mb-3' type='text' name='basics.location.countryCode' value={getValue(formData.basics.location.countryCode)} onChange={handleChange} placeholder='Country Code' />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-4 col-md-2 col-sm-3'>
                <label className="form-label" style={{padding:'10px'}}>Image</label>
              </div>
              <div className='col-8 col-md-10 col-sm-9'>
                <input className='form-control mb-3' type='text' name='basics.image' value={getValue(formData.basics.image)} onChange={handleChange} placeholder='Image URL' />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-2 col-sm-1'>
                <label className="form-label" style={{padding:'10px'}}>About<span className="text-danger">*</span></label>
              </div>
              <div className='col-10 col-sm-11'>
                <textarea className={requiredFieldClass} name='basics.summary' value={getValue(formData.basics.summary)} onChange={handleChange} placeholder='Summary' required></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
            <h3>Profiles</h3>
            <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('profile')}>
                {visibleSections.profile ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
            </button>
        </div>
        {visibleSections.profile && formData.basics.profiles.map((profile, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Network</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <select className='form-control mb-3' name='network' value={getValue(profile.network)} onChange={(e) => handleNestedArrayChange('basics', 'profiles', index, e)}>
                      <option value='' disabled>Select Network</option>
                      {profileNetworkOptions.map((option, i) => (
                        <option key={i} value={option.key}>{option.value}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Username</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='username' value={getValue(profile.username)} onChange={(e) => handleNestedArrayChange('basics', 'profiles', index, e)} placeholder='Username' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>Profile URL</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(profile.url)} onChange={(e) => handleNestedArrayChange('basics', 'profiles', index, e)} placeholder='Profile URL' />
                  </div>
                </div>
              </div>
            </div>
            {formData.basics.profiles.length > 1 && (
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center' }}>
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleNestedArrayRemoveClick('basics', 'profiles', index)}>Remove</button>
                </div>
              </div>
            )}
          </div>
        ))}
        { visibleSections.profile &&
        <div className='row'>
          <div className='col-12' style={{ textAlign: 'center' }}>
            <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleNestedArrayAddClick('basics', 'profiles', { network: '', username: '', url: '' })}>Add Profile</button>
          </div>
        </div>}
      </div>

      <div className='section mb-5'>
        <h2>Skills</h2>
        {formData.skills.map((skill, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Skill Category<span className="text-danger">*</span></label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <select className='form-control mb-3' name='name' value={getValue(skill.name)} onChange={(e) => handleArrayChange('skills', index, e)} required>
                      <option value='' disabled>Select Skill Category</option>
                      {skillsOptions.map((option, i) => (
                        <option key={i} value={option.key}>{option.value}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Level</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <select className='form-control mb-3' name='level' value={getValue(skill.level)} onChange={(e) => handleArrayChange('skills', index, e)}>
                      <option value='' disabled>Select Level</option>
                      {levelOptions.map((level, i) => (
                        <option key={i} value={level}>{level}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-3 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>Keywords<span className="text-danger">*</span></label>
                  </div>
                  <div className='col-9 col-sm-11'>
                    <Multiselect
                      onSearch={(keyword) => {afterSearch(keyword, index); }}
                      options={skillKeywords}
                      selectedValues={skill.keywords.map(keyword => skillKeywords.find(sk => sk.id === keyword) || { name: `${keyword}`, id: keyword } )}
                      onSelect={(list) => handleKeywordsChange(list, index)}
                      onRemove={(list) => handleKeywordsChange(list, index)}
                      displayValue='name'
                      ref={multiselectRef}
                      style={{
                        multiselectContainer: { color: 'black' },
                        inputField: { color: 'white' }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='mb-3'></div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.skills.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('skills', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='col-12' style={{ textAlign: 'center' }}>
            <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('skills', { name: '', level: '', keywords: [] })}>Add Skill</button>
          </div>
        </div>
      </div>

       <div className='section mb-5'>
         <h2>Education</h2>
         {formData.education.map((education, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Institution<span className="text-danger">*</span></label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='institution' value={getValue(education.institution)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='Institution' required />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>URL</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(education.url)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='URL' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Area</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='area' value={getValue(education.area)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='Area' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Degree<span className="text-danger">*</span></label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='studyType' value={getValue(education.studyType)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='Study Type' required />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`education.startDate.${index}`}>
                      Start<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='startDate' value={getValue(education.startDate)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='Start Date' required />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`education.endDate.${index}`}>
                      End<span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='endDate' value={getValue(education.endDate)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='End Date' required />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>Score<span className="text-danger">*</span></label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <input className='form-control mb-3' type='text' name='score' value={getValue(education.score)} onChange={(e) => handleArrayChange('education', index, e)} placeholder='Score' required />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.education.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('education', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        <div className='row'>
          <div className='col-12' style={{ textAlign: 'center' }}>
            <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('education', { institution: '', url: '', area: '', studyType: '', startDate: '', endDate: '', score: '' })}>Add Education</button>
          </div>
        </div>
      </div>


       <div className='section mb-5'>
       <div className='d-flex justify-content-between align-items-center'>
          <h2>Work Experience</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('work')}>
            {visibleSections.work ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
         {visibleSections.work && formData.work.map((work, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Company Name</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='name' value={getValue(work.name)} onChange={(e) => handleArrayChange('work', index, e)} placeholder='Company Name' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Position</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='position' value={getValue(work.position)} onChange={(e) => handleArrayChange('work', index, e)} placeholder='Position' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Company URL</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(work.url)} onChange={(e) => handleArrayChange('work', index, e)} placeholder='Company URL' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`work.startDate.${index}`}>
                      Start
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='startDate' value={getValue(work.startDate)} onChange={(e) => handleArrayChange('work', index, e)} placeholder='Start Date'/>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`work.endDate.${index}`}>
                      End <small> (blank for PRESENT) </small>
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='endDate' value={getValue(work.endDate)} onChange={(e) => handleArrayChange('work', index, e)} placeholder='End Date - leave blank for PRESENT' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>About</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <textarea className='form-control mb-3' name='summary' value={getValue(work.summary)} onChange={(e) => handleArrayChange('work', index, e)} placeholder='Summary'></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.work.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('work', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        { visibleSections.work &&
        <div className='row'>
          <div className='col-12' style={{ textAlign: 'center' }}>
            <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('work', { name: '', position: '', url: '', startDate: '', endDate: '', summary: '' })}>Add Work Experience</button>
          </div>
        </div>
        }
      </div>


      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Volunteer Experience</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('volunteer')}>
            {visibleSections.volunteer ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.volunteer && formData.volunteer.map((volunteer, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Organization</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='organization' value={getValue(volunteer.organization)} onChange={(e) => handleArrayChange('volunteer', index, e)} placeholder='Organization' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Position</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='position' value={getValue(volunteer.position)} onChange={(e) => handleArrayChange('volunteer', index, e)} placeholder='Position' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>URL</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(volunteer.url)} onChange={(e) => handleArrayChange('volunteer', index, e)} placeholder='URL' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`volunteer.startDate.${index}`}>
                      Start
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='startDate' value={getValue(volunteer.startDate)} onChange={(e) => handleArrayChange('volunteer', index, e)} placeholder='Start Date' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`volunteer.endDate.${index}`}>
                      End <small> (blank for PRESENT) </small>
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='endDate' value={getValue(volunteer.endDate)} onChange={(e) => handleArrayChange('volunteer', index, e)} placeholder='End Date' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>About</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <textarea className='form-control mb-3' name='summary' value={getValue(volunteer.summary)} onChange={(e) => handleArrayChange('volunteer', index, e)} placeholder='Summary'></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.volunteer.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('volunteer', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.volunteer && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('volunteer', { organization: '', position: '', url: '', startDate: '', endDate: '', summary: '' })}>Add Volunteer Experience</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Awards</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('awards')}>
            {visibleSections.awards ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.awards && formData.awards.map((award, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Title</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='title' value={getValue(award.title)} onChange={(e) => handleArrayChange('awards', index, e)} placeholder='Title' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Awarder</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='awarder' value={getValue(award.awarder)} onChange={(e) => handleArrayChange('awards', index, e)} placeholder='Awarder' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`awards.date.${index}`}>
                      Received
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='date' value={getValue(award.date)} onChange={(e) => handleArrayChange('awards', index, e)} placeholder='Receiving Date' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>About</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <textarea className='form-control mb-3' name='summary' value={getValue(award.summary)} onChange={(e) => handleArrayChange('awards', index, e)} placeholder='Summary'></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.awards.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('awards', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.awards && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('awards', { title: '', date: '', awarder: '', summary: '' })}>Add Award</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Certificates</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('certificates')}>
            {visibleSections.certificates ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.certificates && formData.certificates.map((certificate, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Name</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='name' value={getValue(certificate.name)} onChange={(e) => handleArrayChange('certificates', index, e)} placeholder='Name' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Issuer</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='issuer' value={getValue(certificate.issuer)} onChange={(e) => handleArrayChange('certificates', index, e)} placeholder='Issuer' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`certificates.date.${index}`}>
                      Completion
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='date' value={getValue(certificate.date)} onChange={(e) => handleArrayChange('certificates', index, e)} placeholder='Completion Date' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>URL</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(certificate.url)} onChange={(e) => handleArrayChange('certificates', index, e)} placeholder='URL' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.certificates.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('certificates', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.certificates && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('certificates', { name: '', date: '', issuer: '', url: '' })}>Add Certificate</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Publications</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('publications')}>
            {visibleSections.publications ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.publications && formData.publications.map((publication, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Name</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='name' value={getValue(publication.name)} onChange={(e) => handleArrayChange('publications', index, e)} placeholder='Name' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Publisher</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='publisher' value={getValue(publication.publisher)} onChange={(e) => handleArrayChange('publications', index, e)} placeholder='Publisher' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`publications.releaseDate.${index}`}>
                      Release
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='releaseDate' value={getValue(publication.releaseDate)} onChange={(e) => handleArrayChange('publications', index, e)} placeholder='Release Date' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>URL</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(publication.url)} onChange={(e) => handleArrayChange('publications', index, e)} placeholder='URL' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>About</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <textarea className='form-control mb-3' name='summary' value={getValue(publication.summary)} onChange={(e) => handleArrayChange('publications', index, e)} placeholder='Summary'></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.publications.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('publications', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.publications && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('publications', { name: '', publisher: '', releaseDate: '', url: '', summary: '' })}>Add Publication</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Languages</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('languages')}>
            {visibleSections.languages ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.languages && formData.languages.map((language, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Language</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='language' value={getValue(language.language)} onChange={(e) => handleArrayChange('languages', index, e)} placeholder='Language' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Fluency</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='fluency' value={getValue(language.fluency)} onChange={(e) => handleArrayChange('languages', index, e)} placeholder='Fluency' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.languages.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('languages', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.languages && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('languages', { language: '', fluency: '' })}>Add Language</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Interests</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('interests')}>
            {visibleSections.interests ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.interests && formData.interests.map((interest, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Interest</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='name' value={getValue(interest.name)} onChange={(e) => handleArrayChange('interests', index, e)} placeholder='Interest' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-4'>
                    <label className="form-label" style={{padding:'10px'}}>Keywords</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-8'>
                    <input className='form-control mb-3' type='text' name='keywords' value={getValue(interest.keywords)} onChange={(e) => handleArrayChange('interests', index, e)} placeholder='Keywords' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.interests.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('interests', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.interests && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('interests', { name: '', keywords: [''] })}>Add Interest</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>References</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('references')}>
            {visibleSections.references ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.references && formData.references.map((reference, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Name</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='name' value={getValue(reference.name)} onChange={(e) => handleArrayChange('references', index, e)} placeholder='Name' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Reference</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <textarea className='form-control mb-3' name='reference' value={getValue(reference.reference)} onChange={(e) => handleArrayChange('references', index, e)} placeholder='Reference'></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.references.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('references', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.references && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('references', { name: '', reference: '' })}>Add Reference</button>
            </div>
          </div>
        )}
      </div>

      <div className='section mb-5'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2>Projects</h2>
          <button type='button' className='btn' style={{marginTop:'-20px'}} onClick={() => toggleSectionVisibility('projects')}>
            {visibleSections.projects ? <div style={{color:'white', fontSize:'50px'}}>-</div> : <div style={{color:'white', fontSize:'30px'}}>▼</div>}
          </button>
        </div>
        {visibleSections.projects && formData.projects.map((project, index) => (
          <div key={index}>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>Name</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='name' value={getValue(project.name)} onChange={(e) => handleArrayChange('projects', index, e)} placeholder='Project Name' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className="form-label" style={{padding:'10px'}}>URL</label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='text' name='url' value={getValue(project.url)} onChange={(e) => handleArrayChange('projects', index, e)} placeholder='URL' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`projects.startDate.${index}`}>
                      Start
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='startDate' value={getValue(project.startDate)} onChange={(e) => handleArrayChange('projects', index, e)} placeholder='Start Date' />
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6'>
                <div className='row'>
                  <div className='col-4 col-md-2 col-sm-3'>
                    <label className='form-label' style={{padding:'10px'}} htmlFor={`projects.endDate.${index}`}>
                      End <small> (blank for PRESENT) </small>
                    </label>
                  </div>
                  <div className='col-8 col-md-10 col-sm-9'>
                    <input className='form-control mb-3' type='date' name='endDate' value={getValue(project.endDate)} onChange={(e) => handleArrayChange('projects', index, e)} placeholder='End Date' />
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <div className='row'>
                  <div className='col-2 col-sm-1'>
                    <label className="form-label" style={{padding:'10px'}}>About</label>
                  </div>
                  <div className='col-10 col-sm-11'>
                    <textarea className='form-control mb-3' name='description' value={getValue(project.description)} onChange={(e) => handleArrayChange('projects', index, e)} placeholder='Description'></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-12' style={{ textAlign: 'center' }}>
                {formData.projects.length > 1 && (
                  <button type='button' className='btn btn-danger mb-3' style={{minWidth:'30%'}} onClick={() => handleArrayRemoveClick('projects', index)}>Remove</button>
                )}
              </div>
            </div>
          </div>
        ))}
        {visibleSections.projects && (
          <div className='row'>
            <div className='col-12' style={{ textAlign: 'center' }}>
              <button type='button' className='btn btn-success mb-3' style={{minWidth:'50%'}} onClick={() => handleArrayAddClick('projects', { name: '', startDate: '', endDate: '', description: '', url: '' })}>Add Project</button>
            </div>
          </div>
        )}
      </div>

      <div className='row'>
        <div className='col-3'></div>
        <div className='col-6'>
          <button className='btn btn-lg btn-light form-control' type='submit'>
            Save & Continue
          </button>
        </div>
        <div className='col-3'></div>
      </div>
      <Loader />
    </form>
        </div>
  );
};

export default PersonalInfoForm;

