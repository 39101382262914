import React, { useEffect, useState, useContext } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/ResumeComparison.module.css';
import { LoadingContext } from './LoadingContext';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from './FirebaseConfig';
import { toast } from 'react-toastify';
import axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { analytics } from './FirebaseConfig';

const ResumeComparison = () => {

  logEvent(analytics, 'Comparison_Page');
  const navigate = useNavigate();
  const [originalResume, setOriginalResume] = useState(null);
  const [newResume, setNewResume] = useState(null);
  const [highlightedLines, setHighlightedLines] = useState([]);
  const { loading, setLoading } = useContext(LoadingContext);

  useEffect(() => {
    const storedOriginalResume = JSON.parse(removeStrongTags(localStorage.getItem('resumeData')));
    const storedNewResume = JSON.parse(removeStrongTags(localStorage.getItem('newResumeData')));

    const reorderedOriginalResume = mapToSchema(storedOriginalResume);
    const reorderedNewResume = mapToSchema(storedNewResume);

    setOriginalResume(reorderedOriginalResume);
    setNewResume(reorderedNewResume);
  }, []);

  const resumeSchema = {
    basics: {
      name: '',
      label: '',
      image: '',
      email: '',
      phone: '',
      url: '',
      summary: '',
      location: {
        address: '',
        postalCode: '',
        city: '',
        countryCode: '',
        region: ''
      },
      profiles: [{ network: '', username: '', url: '' }]
    },
    work: [{ name: '', position: '', url: '', startDate: '', endDate: '', summary: '', highlights: '' }],
    skills: [{ name: '', level: '', keywords: [''] }],
    education: [{ institution: '', url: '', area: '', studyType: '', startDate: '', endDate: '', score: '' }],
    awards: [{ title: '', date: '', awarder: '', summary: '' }],
    certificates: [{ name: '', date: '', issuer: '', url: '' }],
    publications: [{ name: '', publisher: '', releaseDate: '', url: '', summary: '' }],
    volunteer: [{ organization: '', position: '', url: '', startDate: '', endDate: '', summary: '' }],
    languages: [{ language: '', fluency: '' }],
    interests: [{ name: '', keywords: [''] }],
    references: [{ name: '', reference: '' }],
    projects: [{ name: '', startDate: '', endDate: '', description: '', url: '' }]
  };

  const removeStrongTags = (inputString) => {
    return inputString.replace(/<\/?strong>/g, '');
}

  const mapToSchema = (resume) => {
    const mappedResume = {};

    for (const section in resumeSchema) {
      if (resume && resume[section]) {
        if (Array.isArray(resumeSchema[section])) {
          mappedResume[section] = resume[section].length > 0 ? resume[section].map(item => mapItemToSchema(item, resumeSchema[section][0])) : [...resumeSchema[section]];
        } else if (typeof resumeSchema[section] === 'object') {
          mappedResume[section] = mapItemToSchema(resume[section], resumeSchema[section]);
        } else {
          mappedResume[section] = resume[section] || resumeSchema[section];
        }
      } else {
        mappedResume[section] = Array.isArray(resumeSchema[section]) ? [...resumeSchema[section]] : { ...resumeSchema[section] };
      }
    }

    return mappedResume;
  };

  const mapItemToSchema = (item, schema) => {
    const mappedItem = {};

    for (const key in schema) {
      if (item && item[key] !== undefined) {
        if (Array.isArray(schema[key])) {
          if (key === 'keywords') {
            mappedItem[key] = item[key].length > 0 ? item[key] : [''];
          } else {
            mappedItem[key] = item[key].length > 0 ? item[key].map(subItem => mapItemToSchema(subItem, schema[key][0])) : [...schema[key]];
          }
        } else if (typeof schema[key] === 'object') {
          mappedItem[key] = mapItemToSchema(item[key], schema[key]);
        } else {
          mappedItem[key] = item[key] || schema[key];
        }
      } else {
        mappedItem[key] = Array.isArray(schema[key]) ? [...schema[key]] : schema[key];
      }
    }

    return mappedItem;
  };

  const handleLineNumberClick = (lineId) => {
    if (lineId.startsWith('R')) {
      setHighlightedLines((prevLines) => {
        if (!prevLines.includes(lineId)) {
          return [...prevLines, lineId];
        } else {
          return prevLines.filter(line => line !== lineId);
        }
      });
    }
  };

  const handleSaveChanges = async () => {
    if (!originalResume || !newResume) return;

    const updatedResume = { ...originalResume };
    const newResumeLines = JSON.stringify(newResume, null, 2).split('\n');
    const updatedResumeLines = JSON.stringify(updatedResume, null, 2).split('\n');

    highlightedLines.forEach(lineId => {
      const lineNumber = parseInt(lineId.split('-')[1], 10) - 1;
      if (lineId.startsWith('R')) {
        updatedResumeLines[lineNumber] = newResumeLines[lineNumber];
      }
    });

    let resume =updatedResumeLines.join("\n");

    try {
        setLoading(true);
        const user = auth.currentUser;
        if (!user) {
          toast.error("Unauthenticated");
          throw new Error('User is not authenticated');
        }
        const token = await user.getIdToken(true);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/map-resume`, {resume}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      resume = response.data.resume;
      //Object.assign(updatedResume, JSON.parse(response.data));
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.error("Couldn't update resume");
      return;
    }

    localStorage.setItem('resumeData', resume);

    // Save to Firestore
    try {
      setLoading(true);
      const user = auth.currentUser;
      if (!user) {
        toast.error("Unauthenticated");
        throw new Error('User is not authenticated');
      }

      const parsedResume = JSON.parse(resume);
      const userId = user.uid;
      await setDoc(doc(db, 'resumes', userId), parsedResume);

      navigate('/me');
    } catch (error) {
        setLoading(false);
        toast.error("Could not update your resume");
    }
  };

  const handleDiscardChanges = () => {
    navigate("/me");
  };

  return (
    <div className={styles.container}>
      <h2>Resume Comparison</h2>
      <h5>(Click on the line number below to mark your choice)</h5>
      {originalResume && newResume ? (
        <div className={styles.comparison}>
          <ReactDiffViewer
            oldValue={JSON.stringify(originalResume, null, 2)}
            newValue={JSON.stringify(newResume, null, 2)}
            splitView={true}
            disableWordDiff={true}
            hideLineNumbers={false}
            showDiffOnly={false}
            useDarkTheme={true}
            leftTitle="Original Resume"
            rightTitle="Updated Resume"
            compareMethod={DiffMethod.CSS}
            highlightLines={highlightedLines}
            onLineNumberClick={handleLineNumberClick}
            styles={{
              variables: {
                dark: {
                  codeFoldGutterBackground: '#e0ffe0',
                  codeFoldBackground: '#e0ffe0',
                  gutterBackground: '#272c34',
                  gutterBackgroundDark: '#272c34',
                  gutterColor: '#ffffff',
                  lineNumberColor: '#ffffff',
                  background: '#272c34',
                  codeFoldGutterBackgroundDark: '#21252b',
                  codeFoldBackgroundDark: '#21252b',
                  gutterColorDark: '#d4d4d4',
                  diffViewerTitleColor: 'white',
                  diffViewerBackground: '#272c34',
                },
              },
              diffContainer: {
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overflow: 'auto',
              },
            }}
          />
        </div>
      ) : (
        <p>Loading resumes...</p>
      )}
      <br />
      <div>
        <button onClick={handleSaveChanges} className="btn btn-success">Save Changes</button>
        <button onClick={handleDiscardChanges} className="btn btn-danger" style={{ marginLeft: '10px' }}>Discard Changes</button>
      </div>
    </div>
  );
};

export default ResumeComparison;

